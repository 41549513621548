import dataGameOne from '@/data/gameOneData.json';
import MediatorAction from '@/features/devices/tablets/mediator/MediatorAction';
import {
    handleAllLeversFound,
    handleAllRisksFound,
    handleCurrentJobGame1,
    handleJobOverlay,
    handleRisksAndLeversReset,
    handleUpdateGameOneAudioJobStatus,
    handleUpdateRisk,
    handleUpdateRiskOrLeverStatus,
    handleValidateGame1RiskOrLever,
} from '@/services/games/game1/gameOneHandleFunctions';
import {
    getNextJobGame1,
    getPreviousJobGame1,
    getSelectedLever,
    getSelectedRisk,
    isRiskFound,
} from '@/services/games/game1/gameOneUtils';
import { handleUpdateOverlayDescriptions } from '@/services/global/globalHandleFunctions';
import { getJobsData } from '@/services/global/globalUtils';
import { useGame1Store } from '@/store/games/game1Store';
import { useOverlayStore } from '@/store/overlayStore';
import { type JobGame1 } from '@/types/games/game1/types';
import { OverlayType } from '@/types/global/types';
import { useEffect } from 'react';

const GameOneMediator: React.FC = () => {
    const {
        audioShouldPlay,
        job: currentJob,
        leversFound: isLeversFound,
        risks: storedRisks,
        levers: storedLevers,
        risksFound: isRisksFound,
    } = useGame1Store();

    const { overlayDescriptions } = useOverlayStore();
    const areRisksValidated = currentJob.risksValidated;
    const selectedRisk = getSelectedRisk();
    const selectedLever = getSelectedLever();

    const showValidateRisksOrLevers = areRisksValidated
        ? !!selectedLever
        : !!selectedRisk;

    const nextJob = getNextJobGame1(getJobsData(dataGameOne.jobs) as JobGame1[]);
    const previousJob = getPreviousJobGame1(
        getJobsData(dataGameOne.jobs) as JobGame1[]
    );

    useEffect(() => {
        if (storedRisks.length !== dataGameOne.risks.length) return;
        handleAllRisksFound();
    }, [storedRisks.length]);

    return (
        <div>
            {/* Play the current job description */}
            <MediatorAction
                title='Jouer la description du métier'
                displayingCondition={
                    // Used to show the risks
                    (overlayDescriptions.length === 0 &&
                        isRisksFound &&
                        !areRisksValidated) ||
                    // Used to show the levers
                    (overlayDescriptions.length === 0 &&
                        areRisksValidated &&
                        isLeversFound)
                }
                handleFunction={() =>
                    handleUpdateGameOneAudioJobStatus(!audioShouldPlay)
                }
            />

            {/*  Go to next job */}
            <MediatorAction
                title='Passer au métier suivant'
                displayingCondition={
                    isRisksFound &&
                    isLeversFound &&
                    !!nextJob &&
                    overlayDescriptions.length === 0
                }
                handleFunction={() => {
                    handleRisksAndLeversReset('isSelected');
                    handleRisksAndLeversReset('isValidated');
                    handleCurrentJobGame1(nextJob);
                }}
                type='tertiary'
            />

            {/* Go to previous job */}
            <MediatorAction
                title='Revenir au métier précédent'
                displayingCondition={
                    isRisksFound &&
                    isLeversFound &&
                    !!previousJob &&
                    overlayDescriptions.length === 0
                }
                handleFunction={() => {
                    handleRisksAndLeversReset('isSelected');
                    handleRisksAndLeversReset('isValidated');
                    handleCurrentJobGame1(previousJob);
                    handleUpdateOverlayDescriptions([], OverlayType.NEUTRAL);
                }}
                type='tertiary'
            />

            {/* Skip the overlay message */}
            <MediatorAction
                title='Passer le message'
                displayingCondition={overlayDescriptions.length > 0}
                handleFunction={handleJobOverlay}
                type='secondary'
            />

            {/* Unlock all risks */}
            <MediatorAction
                title='Débloquer tous les risques'
                displayingCondition={
                    overlayDescriptions.length === 0 &&
                    !isRisksFound &&
                    !isLeversFound
                }
                handleFunction={() => {
                    dataGameOne.risks.forEach((risk) => {
                        if (isRiskFound(risk)) return;
                        handleUpdateRisk({ ...risk, isSelected: false });
                    });
                }}
                type='secondary'
            />

            {/* Unlock risk */}
            {dataGameOne.risks.map((risk) => (
                <MediatorAction
                    key={risk.id}
                    title={`Risque: ${risk.name}`}
                    displayingCondition={!isRiskFound(risk)}
                    handleFunction={() =>
                        handleUpdateRisk({ ...risk, isSelected: false })
                    }
                    type='secondary'
                />
            ))}

            {/* Unlock levers */}
            <MediatorAction
                title='Débloquer les leviers'
                displayingCondition={
                    overlayDescriptions.length === 0 &&
                    isRisksFound &&
                    !isLeversFound &&
                    currentJob.risksValidated
                }
                handleFunction={handleAllLeversFound}
                type='secondary'
            />

            {/* Validate risks or levers */}
            <MediatorAction
                title={`Valider le ${areRisksValidated ? 'levier' : 'risque'} ${
                    areRisksValidated ? selectedLever?.name : selectedRisk?.name
                }`}
                displayingCondition={
                    overlayDescriptions.length === 0 && showValidateRisksOrLevers
                }
                handleFunction={() =>
                    handleValidateGame1RiskOrLever(
                        areRisksValidated ? 'levers' : 'risks'
                    )
                }
                type='secondary'
            />

            {/* Select risk or levers current job */}
            {currentJob[`${areRisksValidated ? 'levers' : 'risks'}Id`]?.map(
                (id) => {
                    const item = areRisksValidated
                        ? storedLevers.find((lever) => lever.id === id)
                        : storedRisks.find((risk) => risk.id === id);

                    return (
                        <MediatorAction
                            key={id}
                            title={`Sélectionner le ${
                                areRisksValidated ? 'levier' : 'risque'
                            } ${item?.name}`}
                            displayingCondition={
                                overlayDescriptions.length === 0 &&
                                !item?.isSelected &&
                                !item?.isValidated &&
                                (areRisksValidated ? isLeversFound : isRisksFound)
                            }
                            handleFunction={() =>
                                handleUpdateRiskOrLeverStatus(
                                    id,
                                    areRisksValidated ? 'levers' : 'risks',
                                    'isSelected',
                                    true
                                )
                            }
                            type='secondary'
                        />
                    );
                }
            )}
        </div>
    );
};

export default GameOneMediator;
