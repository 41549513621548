import { WinPadCode, WrongPadCode } from '@/assets/sounds/soundsEffects';
import data from '@/data/gameOneData.json';
import globalData from '@/data/global.json';
import InputUnlockCode from '@/features/devices/tablets/components/inputUnluckCode/InputUnlockCode';
import DigitKeypad from '@/features/devices/tablets/components/keypad/DigitKeypad';
import { MAX_CODE_LENGTH_RISKS } from '@/features/devices/tablets/components/keypad/DigitKeypadScreen';
import styles from '@/features/devices/tablets/components/keypad/digitKeypadScreen.module.scss';
import Popup from '@/features/devices/tablets/components/popup/Popup';
import ProgressBar from '@/features/devices/tablets/components/progressBar/ProgressBar';
import {
    handleAllRisksFound,
    handleUpdateRisk,
} from '@/services/games/game1/gameOneHandleFunctions';
import { getRiskFromCode, isRiskFound } from '@/services/games/game1/gameOneUtils';
import { playSound } from '@/services/global/globalHandleFunctions';
import { totalRisks } from '@/services/global/globalUtils';
import { useGame1Store } from '@/store/games/game1Store';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { type Risk } from '@/types/games/game1/types';

const UnlockRisks: React.FC = () => {
    const risks = useGame1Store.use.risks();

    const [code, setCode] = useState<string>('');
    const [lastRiskFound, setLastRiskFound] = useState<Risk>({} as Risk);
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        if (code.length !== MAX_CODE_LENGTH_RISKS) return;
        const currentRisk = getRiskFromCode(code);
        if (currentRisk && !isRiskFound(currentRisk)) {
            setLastRiskFound({ ...currentRisk, isSelected: false });
            handleUpdateRisk({ ...currentRisk, isSelected: false });
            setShowPopup(true);
        } else {
            playSound(WrongPadCode);
        }
        setCode('');
    }, [code]);

    useEffect(() => {
        if (showPopup) {
            const timer = setTimeout(() => {
                if (risks.length === totalRisks) handleAllRisksFound();
                setShowPopup(false);
            }, 2500);
            return () => clearTimeout(timer);
        }
    }, [showPopup, risks.length]);

    return (
        <div className={styles.risksContainer}>
            <ProgressBar
                title='Risques trouvés'
                numberOfItemCompleted={risks.length}
                totalNumberOfItems={data.risks.length}
            />

            <DigitKeypad setCode={setCode} />
            <AnimatePresence>
                {showPopup && (
                    <Popup
                        title={globalData.global.congratulations}
                        text={globalData.tv.risksUnlocked + lastRiskFound?.name}
                        audio={WinPadCode}
                    />
                )}
            </AnimatePresence>
            {!showPopup && <InputUnlockCode code={code} />}
        </div>
    );
};

export default UnlockRisks;
