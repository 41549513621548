import { WinPadCode } from '@/assets/sounds/soundsEffects';
import data from '@/data/gameOneData.json';
import globalData from '@/data/global.json';
import DialCard from '@/features/devices/tablets/components/dial/DialCard';
import {
    MAX_CODE_LENGTH_LEVERS,
    MAX_CODE_LENGTH_RISKS,
} from '@/features/devices/tablets/components/keypad/DigitKeypadScreen';
import styles from '@/features/devices/tablets/components/keypad/digitKeypadScreen.module.scss';
import Popup from '@/features/devices/tablets/components/popup/Popup';
import { handleAllLeversFound } from '@/services/games/game1/gameOneHandleFunctions';
import { SHAKE_ANIMATION } from '@/services/global/globalUtils';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { type Icon } from '@/types/games/game1/types';

const UnlockLevers: React.FC = () => {
    const controls = useAnimation();

    const [selectedIcons, setSelectedIcons] = useState<Icon[]>([]);
    const [showPopup, setShowPopup] = useState(false);

    const goodIconsCombinationIds = data.leversUnlockingCodeIds;

    useEffect(() => {
        if (selectedIcons.length !== MAX_CODE_LENGTH_LEVERS) return;

        const selectedIconsIds = selectedIcons.map((icon) => icon.id);
        const isGoodCombination = goodIconsCombinationIds.every((id) =>
            selectedIconsIds.includes(id)
        );

        if (isGoodCombination) {
            setShowPopup(true);
            return;
        }

        controls.start(SHAKE_ANIMATION);
        setSelectedIcons([]);

        // I disable the rule because I dont want to add goodIconsCombinationIds as a dependency because it is a constant
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIcons, controls]);

    useEffect(() => {
        if (showPopup) {
            const timer = setTimeout(() => {
                handleAllLeversFound();
                setShowPopup(false);
            }, 2500);
            return () => clearTimeout(timer);
        }
    }, [showPopup]);

    const selectedIconsArray = useMemo(
        () =>
            Array.from({ length: MAX_CODE_LENGTH_LEVERS }).map((_, index) => {
                return (
                    <DialCard
                        content={selectedIcons[index]?.image || '?'}
                        key={index}
                    />
                );
            }),
        [selectedIcons]
    );

    const handleClick = useCallback((icon: Icon) => {
        setSelectedIcons((prev) => [...prev, icon]);
    }, []);

    const iconsArray = useMemo(
        () =>
            data.icons
                .sort(() => Math.random() - 0.5)
                .map((icon) => (
                    <DialCard
                        content={icon.image}
                        key={icon.name}
                        handleClick={() => handleClick(icon)}
                    />
                )),
        [handleClick]
    );

    return (
        <div className={styles.leversContainer}>
            <motion.div className={styles.leversInput} animate={controls}>
                {selectedIconsArray}
            </motion.div>
            <div className={styles.keyboard}>{iconsArray}</div>
            <AnimatePresence>
                {showPopup && (
                    <Popup
                        title={globalData.global.congratulations}
                        text={globalData.tv.leversUnlocked}
                        audio={WinPadCode}
                    />
                )}
            </AnimatePresence>
        </div>
    );
};

export default UnlockLevers;
