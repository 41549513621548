import UnlockLevers from '@/features/devices/tablets/components/keypad/UnlockLevers';
import UnlockRisks from '@/features/devices/tablets/components/keypad/UnlockRisks';
import { useGame1Store } from '@/store/games/game1Store';

export const MAX_CODE_LENGTH_RISKS = 4;
export const MAX_CODE_LENGTH_LEVERS = 6;

const DigitKeypadScreen: React.FC = () => {
    const risksFound = useGame1Store.use.risksFound();
    return <>{risksFound ? <UnlockLevers /> : <UnlockRisks />}</>;
};

export default DigitKeypadScreen;
