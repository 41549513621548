// scss
import styles from '@/features/components/modal/carouselConversation/conversation.module.scss';
//assets
import AudioComponent from '@/features/components/AudioComponent';
import TextAnimation from '@/features/components/text/TextAnimation';

interface ConversationProps {
    text?: string;
    title?: string | null;
    audio?: string;
}

const Conversation: React.FC<ConversationProps> = ({
    text,
    title = null,
    audio,
}) => {
    return (
        <div className={styles.container}>
            <AudioComponent src={audio ?? ''} trackSource={text} />
            <div>
                {title && (
                    <div className={styles.title}>
                        <h2>{title}</h2>
                    </div>
                )}
                {text && <TextAnimation text={text} />}
            </div>
        </div>
    );
};

export default Conversation;
