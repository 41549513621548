import data from '@/data/gameTwoData.json';
import { getJobsData } from '@/services/global/globalUtils';
import createSelectors from '@/store/selectors';
import {
    ExposureStatus,
    type Game2Store,
    type JobOmitGame2,
} from '@/types/games/game2/types';
import { create } from 'zustand';

export const defaultJobs2 = getJobsData(data.jobsExposure).map((job) => ({
    ...(job as JobOmitGame2),
    status: ExposureStatus.NOT_SELECTED,
}));

const initialState: Game2Store = {
    jobsGame2: defaultJobs2,
    selectedJob: null,
};

const useGame2StoreBase = create(() => structuredClone(initialState));

export const useGame2Store = createSelectors(useGame2StoreBase);

export const setExposureStatus = (
    jobId: number,
    status: keyof typeof ExposureStatus
) => {
    useGame2StoreBase.setState((state) => {
        const newJobsExposure = state.jobsGame2.map((job) => {
            if (job.id === jobId) {
                return {
                    ...job,
                    status,
                };
            }
            return job;
        });
        return { jobsGame2: newJobsExposure };
    });
};

export const setSelectedJobExposure = (job: JobOmitGame2) =>
    useGame2StoreBase.setState({ selectedJob: job });

export const resetGame2 = () =>
    useGame2StoreBase.setState(structuredClone(initialState));
