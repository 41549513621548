import data from '@/data/gameOneData.json';
import { getJobsData } from '@/services/global/globalUtils';
import createSelectors from '@/store/selectors';
import type {
    Game1Store,
    JobOmitGame1,
    Risk,
    Lever,
    JobGame1,
} from '@/types/games/game1/types';
import { create } from 'zustand';

const initialState: Game1Store = {
    risks: [],
    levers: [],
    job: getJobsData(data.jobs)[0] as JobOmitGame1,
    audioShouldPlay: false,
    risksFound: false,
    leversFound: false,
};

const useGame1StoreBase = create(() => initialState);

export const useGame1Store = createSelectors(useGame1StoreBase);

export const setRisks = (risk: Risk) => {
    useGame1StoreBase.setState((state) => ({
        risks: [...state.risks, risk],
    }));
};

export const setLevers = (levers: Lever[]) =>
    useGame1StoreBase.setState({
        levers,
    });

export const setRiskOrLeverSelectedOrValidatedStatus = (
    itemId: number,
    itemType: 'risks' | 'levers',
    fieldToUpdate: 'isSelected' | 'isValidated',
    value: boolean
) => {
    useGame1StoreBase.setState((state) => {
        const newItems = state[itemType].map((item) => {
            if (fieldToUpdate === 'isSelected' && value) {
                // Deselect any previously selected item
                if (item[fieldToUpdate]) {
                    return {
                        ...item,
                        [fieldToUpdate]: false,
                    };
                }
            }
            if (item.id === itemId) {
                return {
                    ...item,
                    [fieldToUpdate]: value,
                };
            }
            return item;
        });

        return {
            [itemType]: newItems,
        };
    });
};

export const resetRisksAndLeversSelectedOrValidatedStatus = (
    type: 'isSelected' | 'isValidated'
) => {
    useGame1StoreBase.setState((state) => {
        state.risks.forEach((risk) => {
            risk[type] = undefined;
        });
        state.levers.forEach((lever) => {
            lever[type] = undefined;
        });
        return {
            risks: state.risks,
            levers: state.levers,
        };
    });
};

export const setJobGame1 = (job: Omit<JobGame1, 'jobId'>) => {
    useGame1StoreBase.setState({
        job,
    });
};

export const setAudioShouldPlay = (audioShouldPlay: boolean) =>
    useGame1StoreBase.setState({
        audioShouldPlay,
    });

export const setRiskOrLeversFoundStatus = (
    type: 'risks' | 'levers',
    value: boolean
) => {
    useGame1StoreBase.setState({
        [`${type}Found`]: value,
    });
};

export const resetGame1 = () => useGame1StoreBase.setState(initialState);
